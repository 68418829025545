<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center ">
        <b-img
          src="../../../assets/images/logo/starworks.png"
          style="height: 40px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        class="d-none d-lg-flex align-items-center p-5"
        lg="6"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            alt="Register V2"
            fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        lg="6"
      >
        <b-col
          class="px-xl-2 mx-auto"
          lg="8"
          md="6"
          sm="8"
        >
          <div
            v-if="currentdomain==='impunjab'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/imp.png"
              style="width:60%"
            />
          </div>
          <div
            v-else-if="currentdomain==='ginserv'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/ginserv.png"
              style="width:60%"
            />
          </div>
          <div
            v-else-if="currentdomain==='aghub'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/aghub.png"
              style="width:60%"
            />
          </div>
          <div
            v-else-if="currentdomain==='actionplaniitb'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/abhyuday.png"
              style="width:60%"
            />
          </div>
          <div
            v-else-if="currentdomain==='aic-prestige'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/aic-prestige.png"
              style="width:60%"
            />
          </div>
          <div
            v-else
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/starworks.jpg"
              style="width:60%"
            />
          </div>
          <!-- <b-card-title
            class="mb-1 font-weight-bold text-center"
            title-tag="h2"
          >
            Sign Up 👋
          </b-card-title> -->
          <b-card-text
            class="mt-4 mb-2 text-center"
            style="font-weight:bolder;"
          >
            Please create a new account and start the adventure 🚀
          </b-card-text>
          <div
            v-if="formstate == 0"
            style="display: flex;justify-content: center;"
          >
            <b-button
              variant="light"
              style="display:flex;align-items:center;"
              class="mr-2"
              @click="opengoogleregister()"
            >
              <b-img
                src="../../../assets/images/icons/google.svg"
                style="width:20px;margin-right:6px;"
              />
              <span style="color:#555;padding-top:2px;">Google</span>
            </b-button>
            <b-button
              variant="light"
              style="display:flex;align-items:center;"
            >
              <b-img
                src="../../../assets/images/icons/linkedin.svg"
                style="width:20px;margin-right:6px;"
              />
              <span style="color:#555;padding-top:2px;">LinkedIn</span>
            </b-button>
          </div>
          <b-row
            v-if="formstate == 0"
            class="mt-2"
          >
            <b-col
              sm="5"
              class="d-flex align-items-center"
            >
              <hr style="width:100%;border-color:#6e6b7b;">
            </b-col>
            <b-col sm="2">
              <b-card-text
                class="d-flex align-items-center justify-content-center text-center"
                style="font-weight:bolder;height:100%;"
              >
                Or
              </b-card-text>
            </b-col>
            <b-col
              sm="5"
              class="d-flex align-items-center"
            >
              <hr style="width:100%;border-color:#6e6b7b;">
            </b-col>
          </b-row>
          <!-- form -->
          <validation-observer
            ref="registerForm"
            v-slot="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >

              <b-form-group
                v-if="formstate == 0"
                label="Your Name"
                label-for="register-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="register-name"
                    v-model="full_name"
                    :readonly="resetCodeSent"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="John"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                v-if="formstate == 8 || formstate == 0"
                label="Enter Email"
                label-for="register-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                  vid="email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    :readonly="resetCodeSent"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                v-if="formstate == 8"
                label="Set Password"
                label-for="register-password"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required"
                  vid="password"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      :readonly="resetCodeSent"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="resetCodeSent && formstate == 8"
                label="Confirmation Code"
                label-for="confirmation-code"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirmation Code"
                  rules="required"
                >

                  <b-form-input
                    id="confirmation-code"
                    v-model="resetCode"
                    :state="errors.length > 0 ? false:null"
                    name="confirmation-code"
                    placeholder="012345"
                  />
                  <small class="text-danger">{{ errors[0] }}<br></small>
                  <small v-if="showResendCode">Haven't received the verification code?
                    <b-link
                      variant="link"
                      @click="resendVerificationCode()"
                    >Resend Code
                    </b-link>
                  </small>
                  <small
                    v-if="!showResendCode"
                    class="text-muted"
                  >You can request new code after 30 seconds.</small>
                </validation-provider>
              </b-form-group>

              <div
                v-if="formstate>=1 && formstate<=7"
                class="dotsholder mb-4"
              >
                <div class="dotbox">
                  <div class="dotwrapper">
                    <div
                      :class="`dot ${formstate>=1?'active':''}`"
                      @click="changestatus(1)"
                    >
                      1
                    </div>
                    <div class="backlineholderr">
                      <div :class="`backliner ${formstate>=3?'active':''}`" />
                    </div>
                  </div>
                  <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
                </div>
                <div class="dotbox">
                  <div class="dotwrapper">
                    <div
                      :class="`dot ${formstate>=3?'active':''}`"
                      @click="changestatus(3)"
                    >
                      2
                    </div>
                    <div class="backlineholderl">
                      <div :class="`backlinel ${formstate>=3?'active':''}`" />
                    </div>
                    <div class="backlineholderr">
                      <div :class="`backliner ${formstate>=5?'active':''}`" />
                    </div>
                  </div>
                  <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
                </div>
                <div class="dotbox">
                  <div class="dotwrapper">
                    <div
                      :class="`dot ${formstate>=5?'active':''}`"
                      @click="changestatus(5)"
                    >
                      3
                    </div>
                    <div class="backlineholderl">
                      <div :class="`backlinel ${formstate>=5?'active':''}`" />
                    </div>
                  </div>
                  <!-- <b-card-text class="text-center text-sm" style="color:#6e6b7b">Text</b-card-text> -->
                </div>
              </div>

              <div v-if="formstate==1 || formstate==2">
                <h6 style="font-weight: bolder;">
                  Select Account Type
                </h6>
                <b-row class="mb-1">
                  <b-col xs="4">
                    <div
                      :class="`role-card pt-1 ${accrole=='Startup'?'active':''}`"
                      @click="setrole('Startup')"
                    >
                      <div class="rol-img">
                        <feather-icon
                          icon="StarIcon"
                          size="30"
                        />
                      </div>
                      <h6
                        class="text-center"
                        style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                      >
                        Startup
                      </h6>
                    </div>
                  </b-col>
                  <b-col xs="4">
                    <div
                      :class="`role-card pt-1 ${accrole=='Mentor'?'active':''}`"
                      @click="setrole('Mentor')"
                    >
                      <div class="rol-img">
                        <feather-icon
                          icon="UserIcon"
                          size="30"
                        />
                      </div>
                      <h6
                        class="text-center"
                        style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                      >
                        Mentor
                      </h6>
                    </div>
                  </b-col>
                  <b-col xs="4">
                    <div
                      :class="`role-card pt-1 ${accrole=='Student'?'active':''}`"
                      @click="setrole('Student')"
                    >
                      <div class="rol-img">
                        <feather-icon
                          icon="BookIcon"
                          size="30"
                        />
                      </div>
                      <h6
                        class="text-center"
                        style="padding-top:0.5rem;padding-bottom:0.5rem;color:inherit;"
                      >
                        Student
                      </h6>
                    </div>
                  </b-col>
                </b-row>
                <b-form-group
                  v-if="formstate==2 && accrole=='Startup'"
                  label="Startup Name"
                  label-for="startup-name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="startup-name"
                      v-model="startupname"
                      :state="errors.length > 0 ? false:null"
                      name="startup-name"
                      placeholder="Enter your name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="formstate==2 && accrole=='Startup'"
                  label="Designation"
                  label-for="designation"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      id="designation"
                      v-model="designation"
                      :state="errors.length > 0 ? false:null"
                      name="designation"
                      placeholder="Enter your designation"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="formstate==2"
                  label="About"
                  label-for="designation"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    vid="name"
                  >
                    <b-form-textarea
                      id="designation"
                      v-model="about"
                      :state="errors.length > 0 ? false:null"
                      name="designation"
                      placeholder="Enter Bio"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="formstate==3 || formstate==4">
                <h6 style="font-weight:bolder;">
                  <span
                    v-if="accrole=='Startup'"
                  >Select Relevant Options for Your Startup</span>
                  <span
                    v-else
                  >Select Interest Areas</span>
                </h6>
                <b-form-group
                  v-if="formstate==3 || formstate==4"
                  label="Select Industry"
                  label-for="industry"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Industry"
                    rules="required"
                    vid="industry"
                  >
                    <v-select
                      v-model="industry"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      label="title"
                      :options="industryoptions"
                      @input="nextstate()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="formstate==4"
                  label="Select Lifecycle Stage"
                  label-for="lifecycle"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Lifecycle"
                    rules="required"
                    vid="lifecycle"
                  >
                    <v-select
                      v-model="lifecycle"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      label="title"
                      :options="lifecycleoptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="formstate>=5">
                <b-form-group
                  v-if="formstate==5 || formstate==6 || formstate==7"
                  label="Select Customer Type"
                  label-for="customertype"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Customer Type"
                    rules="required"
                    vid="customertype"
                  >
                    <v-select
                      v-model="customertype"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      label="title"
                      :options="customertypeoptions"
                      @input="nextstate()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="formstate==6 || formstate==7"
                  label="Select Offering Type"
                  label-for="offeringtype"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Offering Type"
                    rules="required"
                    vid="offeringtype"
                  >
                    <v-select
                      v-model="offeringtype"
                      :state="errors.length > 0 ? false:null"
                      label="title"
                      :options="offeringtypeoptions"
                      @input="nextstate()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="formstate==7"
                  label="Select Focus Area"
                  label-for="focusarea"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Focus Area"
                    rules="required"
                    vid="focusarea"
                  >
                    <v-select
                      v-model="focusarea"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      label="title"
                      :options="focusareaoptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <b-button
                :disabled="invalid || loading"
                block
                :type="formstate==8?'submit':'button'"
                variant="primary"
                @click="nextstate"
              >
                <b-spinner
                  v-if="loading && formstate==8"
                  small
                />
                <span
                  v-if="formstate==8"
                  v-text="resetCodeSent ? 'Confirm Sign Up' : 'Sign Up'"
                />
                <span v-else>
                  Next
                </span>
              </b-button>
              <!-- <b-form-group
                label="Register as"
                label-cols="6"
                label-cols-lg="4"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                  vid="role"
                >
                  <b-form-select
                    id="role"
                    v-model="role"
                    :options="[{ value: null, text: 'Please select a role', disabled: true }, {text: 'Startup or Student', value: 'user'}, {text: 'Mentor or Partner', value: 'mentor'}]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- first name -->

            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login', query: $route.query}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BFormTextarea,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Auth } from 'aws-amplify'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      full_name: '',
      startupname: '',
      designation: '',
      about: '',
      role: null,
      accrole: null,
      userEmail: '',
      password: '',
      resetCodeSent: false,
      resetCode: '',
      loading: false,
      showResendCode: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      formstate: 0,
      industry: '',
      lifecycle: '',
      customertype: '',
      offeringtype: '',
      focusarea: '',
      industryoptions: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      lifecycleoptions: ['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],
      customertypeoptions: ['B2C',
        'B2B',
        'B2G',
        'D2C',
        'B2B2C',
        'B2B2B',
        'B2B2G',
        'B2G2C',
        'B2G2B',
        'B2C2C',
        'B2C2B',
        'B2C2G'],
      offeringtypeoptions: ['Product',
        'Service',
        'Hybrid'],
      focusareaoptions: ['Profit',
        'Social Impact',
        'Environmental Impact'],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    opengoogleregister() {
      try {
        Auth.federatedSignIn({ provider: 'Google' })
      } catch (error) {
        // console.log('error:', error)
      }
    },
    changestatus(newstate) {
      if (newstate === 1) {
        if (this.accrole !== null) {
          this.formstate = 2
        } else {
          this.formstate = 1
        }
      } else if (newstate === 3) {
        if (this.industry !== '') {
          this.formstate = 4
        } else {
          this.formstate = 3
        }
      } else if (newstate === 5) {
        if (this.customertype !== '' && this.offeringtype !== '') {
          this.formstate = 7
        } else if (this.customertype !== '') {
          this.formstate = 6
        } else {
          this.formstate = 5
        }
      }
    },
    setrole(newrole) {
      this.accrole = newrole
      this.formstate = 2
    },
    nextstate() {
      if (this.formstate === 0) {
        if (this.full_name !== '' && this.userEmail !== '') {
          this.formstate = 1
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please fill all fields to continue',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 1) {
        if (this.accrole !== null) {
          this.formstate = 2
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select account role to continue',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 2) {
        if (this.accrole === 'Startup') {
          if (this.startupname !== '' && this.designation !== '' && this.about !== '') {
            this.formstate = 3
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please fill the mentioned fields',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.formstate = 3
        }
      } else if (this.formstate === 3) {
        if (this.industry !== '') {
          this.formstate = 4
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select industry',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 4) {
        if (this.industry !== '' && this.lifecycle !== '') {
          this.formstate = 5
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select lifestyle',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 5) {
        if (this.customertype !== '') {
          this.formstate = 6
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select customer type',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 6) {
        if (this.customertype !== '' && this.offeringtype !== '') {
          this.formstate = 7
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select lifestyle',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } else if (this.formstate === 7) {
        if (this.customertype !== '' && this.offeringtype !== '' && this.focusarea !== '') {
          this.formstate = 8
          this.loading = false
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select focus area',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    register() {
      // if (this.formstate === 8) {
      //   this.loading = true
      // }
      if (this.resetCodeSent) {
        return Auth.confirmSignUp(this.userEmail, this.resetCode)
          .then(data => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data === 'SUCCESS' ? 'Registration successful' : 'Failed to register',
                icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
                variant: data === 'SUCCESS' ? 'success' : 'danger',
              },
            })
            if (data === 'SUCCESS') {
              Auth.signIn(this.userEmail, this.password)
                .then(response => {
                  const userData = {}
                  userData.fullName = response.attributes.name
                  userData.userEmail = response.attributes.email
                  if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin')) {
                    userData.role = 'admin'
                    userData.ability = [{
                      action: 'manage',
                      subject: 'Incubator',
                    },
                    {
                      action: 'read',
                      subject: 'Auth',
                    },
                    {
                      action: 'read',
                      subject: 'Dashboard',
                    }]
                  } else if ((jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor'))) {
                    userData.role = 'mentor'
                    userData.ability = [
                      {
                        action: 'read',
                        subject: 'Auth',
                      },
                      {
                        action: 'read',
                        subject: 'Dashboard',
                      },
                      {
                        action: 'manage',
                        subject: 'Mentor',
                      }]
                  } else {
                    userData.role = 'user'
                    userData.ability = [
                      {
                        action: 'read',
                        subject: 'Auth',
                      },
                      {
                        action: 'read',
                        subject: 'Dashboard',
                      },
                      {
                        action: 'manage',
                        subject: 'User',
                      }]
                  }
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
                  this.$ability.update(userData.ability)
                  setTimeout(() => {
                    this.$apollo.query({
                      query: gql`
                          {
                            users_customuser(where: {email: {_eq: "${this.userEmail}"}}) {
                              id
                              first_name
                              email
                              timezone
                              users_associationtables{
                                id
                                organization_id
                                program_id
                                role
                                designation
                                users_organizationtable {
                                  id
                                  title
                                  domain
                                }
                              }
                            }
                          }`,
                    })
                      .then(data2 => {
                        userData.id = data2.data.users_customuser[0].id
                        userData.timezone = JSON.parse(data.users_customuser[0].timezone)
                        userData.associatedOrgDetails = data2.data.users_customuser[0].users_associationtables
                        // ? This is just for demo purpose as well.
                        // ? Because we are showing eCommerce app's cart items count in navbar
                        // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
                        localStorage.setItem('userData', JSON.stringify(userData))
                        localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
                        this.loading = false
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                          },
                        })
                        window.location.href = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/'
                        // this.$router.replace(this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/')
                        // .then(() => {
                        //   this.$toast({
                        //     component: ToastificationContent,
                        //     position: 'top-right',
                        //     props: {
                        //       title: `Welcome ${userData.fullName || userData.username}`,
                        //       icon: 'CoffeeIcon',
                        //       variant: 'success',
                        //       text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                        //     },
                        //   })
                        // })
                        // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                      })
                  }, 2000)
                })
                .catch(error => {
                  this.loading = false
                  this.$refs.loginForm.setErrors({
                    email: error.message,
                    password: ' ',
                  })
                })
              // this.$router.replace({ name: 'auth-login', query: { redirect: this.$route.query.redirect } })
            }
          })
          .catch(err => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
      return this.$refs.registerForm.validate()
        .then(success => {
          if (success) {
            Auth.signUp({
              username: this.userEmail,
              password: this.password,
              attributes: {
                name: this.full_name,
                'custom:role': `${(this.accrole === 'Startup' || this.accrole === 'Student') ? 'user' : 'mentor'}`,
              },
            })
              .then(({ codeDeliveryDetails }) => {
                this.loading = false
                this.resetCodeSent = true
                setTimeout(() => {
                  this.showResendCode = true
                }, 30000)
                return this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `The confirmation code has been sent on your ${codeDeliveryDetails.AttributeName}`,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
                this.$refs.registerForm.setErrors(err.response.data.error)
              })
          }
        })
    },
    resendVerificationCode() {
      this.loading = true
      this.showResendCode = false
      setTimeout(() => {
        this.showResendCode = true
      }, 30000)
      return Auth.resendSignUp(this.userEmail)
        .then(() => {
          this.loading = false
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The confirmation code has been sent again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  apollo: {
    incubator: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}}) {
              id
              logo
              title
              registered_title
            }
          }`
      },
      update: data => {
        this.incubatorname = data.registered_title || data.title || 'Starworks Prime'

        return data.users_organizationtable
      },
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
  .dotsholder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .dotbox{
    width: 25%;
  }

  .dotwrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .dot{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2b2b2b;
    background-color: #e7e7e7;
    font-weight: bolder;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
  }

  .dot.active{
    color: #fff;
    background-color: #7367F0;
  }

  .backlineholderl{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .backlinel{
    width: 50%;
    height: 4px;
    background-color: #e7e7e7;
    margin-top: -4%;
  }
  .backlinel.active{
    background-color: #7367F0;
  }

  .backlineholderr{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .backliner{
    width: 50%;
    height: 4px;
    background-color: #e7e7e7;
    margin-top: -4%;
  }
  .backliner.active{
    background-color: #7367F0;
  }

  .role-card{
    border: solid 1px #7367F0;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #7367F0;
    cursor: pointer;
  }

  .role-card.active{
    background-color: #7367F0;
    color: #fff;
  }

  .rol-img{
    display: flex;
    justify-content: center;
  }
</style>
